import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import {
  Hero,
  Nav,
  Baner,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment"

const Investment = ({ location }) => {
  const title = "Nove Ślichowice"

  return (
    <Layout
      location={location}
      seo={{
        title: "Nove Ślichowice - Nowy wymiar miejskiego życia",
        description:
          "Nowe mieszkania w Kielcach z widokiem na Klasztor Karczówka. Wysoki standard, świetna lokalizacja i dużo zieleni. 51 mieszań, metraże 29-99 m2.",
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Kielce", url: "/en/flats/kielce" }}
        subParent={{ label: "Flats", url: "/en/flats" }}
      />
      <Hero title={title} />
      <Nav title={title} investmentRaw="nove_slichowice" />
      <Baner />
      <Gallery />
      <About title={title} />
      <Location />
      <Convenients />
      <ContactForm
        formType="sale"
        investment="Nove Ślichowice"
        investmentRaw="nove_slichowice"
      />
      {/* <Popup type="kielce" /> */}
    </Layout>
  )
}

export default Investment
